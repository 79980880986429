import './App.css'
import Back from './back.png'
import brand_id_1 from './brand_id_1.jpg'
import brand_id_2 from './brand_id_2.jpg'
import brand_id_3 from './brand_id_3.png'
import brand_id_4 from './brand_id_4.png'
import brand_id_5 from './brand_id_5.png'
import brand_id_6 from './brand_id_6.png'
import brand_id_7 from './brand_id_7.png'
import brand_id_8 from './brand_id_8.jpg'
import brand_id_9 from './brand_id_9.jpg'
import brand_id_10 from './brand_id_10.png'
import brand_id_11 from './brand_id_11.jpg'
import brand_id_12 from './brand_id_12.jpg'
import browserViewGIF from './browserView.gif'

import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  BrowserView,
  MobileView
} from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const list = [
  {
    id: 1,
    title: 'Brand A',
    description: '15% Discount on Weekdays; Dine-in only.',
  },
  {
    id: 2,
    title: 'Brand B',
    description: '30% Discount from 1 to 31 December 2020. Valid for all Christmas items.',
  },
]

const removeEmptyOrNull = (obj) => {
  Object.keys(obj).forEach(k =>
    (obj[k] && typeof obj[k] === 'object') && removeEmptyOrNull(obj[k]) || (!obj[k] && obj[k] !== undefined) && delete obj[k]
  )
  return obj
}

const displayImage = (brand_id) => {
  switch (brand_id) {
    case 1: return (
      <img src={brand_id_1} className='BrandId' alt='brand_id_1' />
    )
    case 2: return (
      <img src={brand_id_2} className='BrandId' alt='brand_id_2' />
    )
    case 3: return (
      <img src={brand_id_3} className='BrandId' alt='brand_id_3' />
    )
    case 4: return (
      <img src={brand_id_4} className='BrandId' alt='brand_id_4' />
    )
    case 5: return (
      <img src={brand_id_5} className='BrandId' alt='brand_id_5' />
    )
    case 6: return (
      <img src={brand_id_6} className='BrandId' alt='brand_id_6' />
    )
    case 7: return (
      <img src={brand_id_7} className='BrandId' alt='brand_id_7' />
    )
    case 8: return (
      <img src={brand_id_8} className='BrandId' alt='brand_id_8' />
    )
    case 9: return (
      <img src={brand_id_9} className='BrandId' alt='brand_id_9' />
    )
    case 10: return (
      <img src={brand_id_10} className='BrandId' alt='brand_id_10' />
    )
    case 11: return (
      <img src={brand_id_11} className='BrandId' alt='brand_id_11' />
    )
    case 12: return (
      <img src={brand_id_12} className='BrandId' alt='brand_id_12' />
    )
  }
}

const Benefits = (props) => {
  const id = props.location.id
  const idToken = props.location.idToken

  const [benefits, setBenefits] = useState('')
  const listBenefits = []

  const history = useHistory()
  const goBack = () => {
    history.replace({
      pathname: '/card',
      id: id,
      idToken: idToken
    })
  }

  useEffect(() => {
    if (idToken == undefined) {
      // Return back to Login Screen
      history.replace({
        pathname: '/',
      })
    } else {

      var config = {
        method: 'get',
        url: 'https://staff-e-card.firebaseio.com/benefits.json?auth=' + idToken
      }

      axios(config)
        .then((response) => {
          setBenefits(removeEmptyOrNull(response.data))
        })
        .catch(function (error) {
          //alert(error)
          alert('Unable to retrieve Benefits')
        })
    }
  })

  return (
    <div className='BenefitsApp'>
      <BrowserView>
        <div className='BrowserView'>
          <img src={browserViewGIF} className='browserViewGIF' alt='browserViewGIF' style={{marginTop: 100}} />
          <h1 style={{marginTop: 40, color: '#fff'}}>Please use a mobile browser</h1>
        </div>
      </BrowserView>
      <MobileView>
        <img src={Back} className='Back' alt='Back' onClick={goBack} />
        <div className='HeaderBar' />
        <div style={{marginTop: 40}}>
          <span className='Header'>
            Benefits
          </span>
        </div>
        <div>
          <ul style={{marginTop: 0}}>
          {
            benefits != '' ? (
              benefits.map(item => (
                item.brand_id != 4 && (
                  <li>
                    <div className='List'>
                      <div style={{marginBottom: 20}}>{displayImage(item.brand_id)}</div>
                      {
                        removeEmptyOrNull(item.benefits).map(points => (
                          <div style={{fontSize: 14, fontWeight: 'bold'}}>- {points}</div>
                        ))
                      }
                      <div style={{marginTop: 20}} />
                      {
                        removeEmptyOrNull(item.tncs).map(points => (
                          <div style={{fontSize: 14, color: '#808080'}}>* {points}</div>
                        ))
                      }
                      <div style={{backgroundColor: '#de523c', height: 1, marginTop: 20}} />
                    </div>
                  </li>                  
                )
              ))
            ) : null
          }
          </ul>
        </div>
      </MobileView>
    </div>
  )
}

export default Benefits
