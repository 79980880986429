import './App.css'
import Logo from './logo_words.png'

import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import readXlsxFile from 'read-excel-file'
import axios from 'axios'

const Admin = (props) => {

  const [idToken, setIdToken] = useState('')
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')
  const [excelFile, setExcelFile] = useState('')
  const [staffRecords, setStaffRecords] = useState([])

  useEffect(() => {
  })

  const handleLoginClick = () => {

    var qs = require('qs');
    var data = qs.stringify({
      'email': id.trim() + '@ouerestaurants.com',
      'password': password,
      'returnSecureToken': true
    })

    var config = {
      method: 'post',
      url: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCNaC8EpNhoC37hA4myOCV9xcrXLuq1JKg',
      data : data
    }

    axios(config)
      .then((response) => {
        //alert(response.data.idToken)

        if (response.data.email == '1688@ouerestaurants.com' || response.data.email == '500103@ouerestaurants.com') {
          setIdToken(response.data.idToken)
        } else {
          alert('You are not an Admin')
        }
      })
      .catch(function (error) {
        //alert(error)
        alert('Invalid ID or Password')
      })
  }

  const handleClick = () => {
    setStaffRecords([])

    readXlsxFile(excelFile[0])
      .then((rows) => {

        for (let i = 0; i < rows.length; i++) {
          var config = {
            method: 'get',
            url: 'https://staff-e-card.firebaseio.com/users.json?print=pretty&orderBy="$key"&equalTo="' + rows[i][0] + '"&auth=' + idToken
          }

          axios(config)
            .then((response) => {
              if (JSON.stringify(response.data).length == 2) {
                // Account does NOT EXIST

                // Create NEW Account (for Login)
                var qs = require('qs');
                var data = qs.stringify({
                  'email': rows[i][0] + '@ouerestaurants.com',
                  'password': rows[i][1],
                  'returnSecureToken': true
                })

                var config = {
                  method: 'post',
                  url: 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCNaC8EpNhoC37hA4myOCV9xcrXLuq1JKg',
                  data : data
                }

                axios(config)
                  .then((response) => {
                    // Create new Profile
                    var config = {
                      method: 'put',
                      url: 'https://staff-e-card.firebaseio.com/users/' + rows[i][0] + '.json?&auth=' + idToken,
                      data: {
                        'id': rows[i][0],
                        'name': rows[i][2],
                        'role': rows[i][3]
                      }
                    }

                    axios(config)
                      .then((response) => {
                        // Update State
                        rows[i][4] = 'New Account'
                        setStaffRecords(staffRecords => [
                          ...staffRecords,
                          rows[i]
                        ])
                      })
                      .catch(function (error) {
                        //alert(error)
                        // Update State
                        rows[i][4] = 'Error (Record Creation)'
                        setStaffRecords(staffRecords => [
                          ...staffRecords,
                          rows[i]
                        ])
                      })
                  })
                  .catch(function (error) {
                    //alert(error)
                    // Update State
                    rows[i][4] = 'Error (Account Creation)'
                    setStaffRecords(staffRecords => [
                      ...staffRecords,
                      rows[i]
                    ])
                  })

              } else {
                // Account EXISTS
                // Account Password CANNOT be CHANGED
                // UPDATE EXISTING Profile
                var config = {
                  method: 'patch',
                  url: 'https://staff-e-card.firebaseio.com/users/' + rows[i][0] + '.json?&auth=' + idToken,
                  data: {
                    'id': rows[i][0],
                    'name': rows[i][2],
                    'role': rows[i][3]
                  }
                }

                axios(config)
                  .then((response) => {
                    // Update State
                    rows[i][4] = 'Update Account'
                    setStaffRecords(staffRecords => [
                      ...staffRecords,
                      rows[i]
                    ])
                  })
                  .catch(function (error) {
                    //alert(error)
                    // Update State
                    rows[i][4] = 'Error (Record Update)'
                    setStaffRecords(staffRecords => [
                      ...staffRecords,
                      rows[i]
                    ])
                  })
              }
            })
            .catch(function (error) {
              //alert(error)
              alert('Unable to access DB')
            })
        }
      })
      .catch(function (error) {
        //alert(error)
        alert('Invalid Excel File')
      })
  }

  return (
    <div className='App'>
      {
        idToken == '' ? (
          <Container className='LoginContainer'>
            <Row className='LogoContainer'>
              <img src={Logo} className='Logo' alt='Logo' />
            </Row>
            <Row>
              <span className='Header'>
                Log In
              </span>
            </Row>
            <Row className='FormContainer'>
              <Form.Control className='FormInput' type='text' placeholder='Staff ID' onChange={e => setId(e.target.value)} style={{ height: 44, borderRadius: 44 }} />
              <Form.Control className='FormInput' type='password' placeholder='Password' onChange={e => setPassword(e.target.value)} style={{ height: 44, borderRadius: 44 }} />
              <div className='FormButton' onClick={handleLoginClick}>
                Log In
              </div>
            </Row>
          </Container>
        ) : (
          <Container className='LoginContainer'>
            <Row className='LogoContainer'>
              <img src={Logo} className='Logo' alt='Logo' />
            </Row>
            <Row>
              <span className='Header'>
                Admin
              </span>
            </Row>
            <Row className='FormContainer'>
              <Form.File id='excelFile' onChange={e => setExcelFile(e.target.files)} />
              <div className='FormButton' onClick={handleClick}>
                Process
              </div>
            </Row>
            {
              staffRecords != '' ? (
                <Row style={{marginTop: 40, fontWeight: 'bold'}}>
                  <Col>
                    Staff ID
                  </Col>
                  <Col>
                    Password
                  </Col>
                  <Col>
                    Name
                  </Col>
                  <Col>
                    Role
                  </Col>
                  <Col>
                    Action
                  </Col>
                </Row>
              ) : null
            }
            {
              staffRecords != '' ? (
                staffRecords.map(record => (
                  <Row>
                    <Col>
                      {record[0]}
                    </Col>
                    <Col>
                      {record[1]}
                    </Col>
                    <Col>
                      {record[2]}
                    </Col>
                    <Col>
                      {record[3]}
                    </Col>
                    <Col>
                      {record[4]}
                    </Col>
                  </Row>
                ))
              ) : null
            }
          </Container>
        )
      }
    </div>
  )
}

export default Admin
