import { render } from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'

import './App.css'
import Login from './Login'
import Card from './Card'
import Benefits from './Benefits'
import Admin from './Admin'

const App = () => (
  <Router>
    <AnimatedSwitch
      atEnter={{ offset: 100 }}
      atLeave={{ offset: 0 }}
      atActive={{ offset: 0 }}
      className='switch-wrapper'
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    >
      <Route exact path='/' component={Login} />
      <Route path='/card' component={Card} />
      <Route path='/benefits' component={Benefits} />
      <Route path='/admin' component={Admin} />
    </AnimatedSwitch>
  </Router>
)

export default App
