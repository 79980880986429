import './App.css'
import Logo from './logo_words.png'
import browserViewGIF from './browserView.gif'

import React, { useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  BrowserView,
  MobileView
} from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const Login = () => {
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')

  const history = useHistory()
  const handleClick = () => {

    var qs = require('qs');
    var data = qs.stringify({
      'email': id.trim() + '@ouerestaurants.com',
      'password': password,
      'returnSecureToken': true
    })

    var config = {
      method: 'post',
      url: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCNaC8EpNhoC37hA4myOCV9xcrXLuq1JKg',
      data : data
    }

    axios(config)
      .then((response) => {
        //alert(response.data.idToken)
        history.replace({
          pathname: '/card',
          id: id,
          idToken: response.data.idToken
        })
      })
      .catch(function (error) {
        //alert(error)
        alert('Invalid ID or Password')
      })
  }

  return (
    <div className='App'>
      <BrowserView>
        <div className='BrowserView'>
          <img src={browserViewGIF} className='browserViewGIF' alt='browserViewGIF' style={{marginTop: 100}} />
          <h1 style={{marginTop: 40, color: '#fff'}}>Please use a mobile browser</h1>
        </div>
      </BrowserView>
      <MobileView>
        <Container className='LoginContainer'>
          <Row className='LogoContainer'>
            <img src={Logo} className='Logo' alt='Logo' />
          </Row>
          <Row>
            <span className='Header'>
              Log In
            </span>
          </Row>
          <Row className='FormContainer'>
            <Form.Control className='FormInput' type='text' placeholder='Staff ID' onChange={e => setId(e.target.value)} style={{ height: 44, borderRadius: 44 }} />
            <Form.Control className='FormInput' type='password' placeholder='Password' onChange={e => setPassword(e.target.value)} style={{ height: 44, borderRadius: 44 }} />
            <div className='FormButton' onClick={handleClick}>
              Log In
            </div>
          </Row>
        </Container>
      </MobileView>
    </div>
  )
}

export default Login
