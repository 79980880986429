import './App.css'
import Logo from './logo_words.png'
import QR_Code from './qr_code.png'
import Exit from './exit.png'
import browserViewGIF from './browserView.gif'

import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  BrowserView,
  MobileView
} from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import Clock from 'react-live-clock'
import axios from 'axios'

const Card = (props) => {
  const id = props.location.id
  const idToken = props.location.idToken

  const [name, setName] = useState('')

  const history = useHistory()
  const goBack = () => {
    history.replace({
      pathname: '/'
    })
  }
  const handleClick = () => {
    history.replace({
      pathname: '/benefits',
      id: id,
      idToken: idToken
    })
  }

  useEffect(() => {
    if (idToken == undefined) {
      // Return back to Login Screen
      history.replace({
        pathname: '/',
      })
    } else {

      const upperCaseId = id.toString().toUpperCase()

      var config = {
        method: 'get',
        url: 'https://staff-e-card.firebaseio.com/users.json?print=pretty&orderBy="$key"&equalTo="' + upperCaseId + '"&auth=' + idToken
      }

      axios(config)
        .then((response) => {
          setName(response.data[upperCaseId].name)
        })
        .catch(function (error) {
          alert('Unable to retrieve Staff Profile')
        })
    }
  })

  let Photo = ''
  let displayId = ''
  if (id != null) {
    Photo = id.toUpperCase() + '.jpg'
    displayId = '*****' + id.toString().substr(-4).toUpperCase()
  }

  return (
    <div className='App'>
      <BrowserView>
        <div className='BrowserView'>
          <img src={browserViewGIF} className='browserViewGIF' alt='browserViewGIF' style={{marginTop: 100}} />
          <h1 style={{marginTop: 40, color: '#fff'}}>Please use a mobile browser</h1>
        </div>
      </BrowserView>
      <MobileView>
        <img src={Exit} className='Exit' alt='Exit' onClick={goBack} />
        <Container className='CardContainer'>
          <Row style={{justifyContent: 'center'}}>
            <div className='Card'>
              <Container>
                <Row>
                  <Col>
                    <img src={`/photos/${Photo}`} onError={(e)=>{e.target.onerror = null; e.target.src='/photos/defaultProfile.jpg'}} className='Photo' style={{marginTop: 10, width: 80, height: 80, borderRadius: 10}} />
                  </Col>
                  <Col>
                    <img src={Logo} className='Logo' alt='Logo' style={{marginTop: 15, marginRight: 0}}/>
                  </Col>
                </Row>
                <Row className='CardDetails'>
                  <Container style={{marginBottom: 25, marginLeft: 15}}>
                    <Row style={{fontWeight: 'bold'}}>
                      {displayId}
                    </Row>
                    <Row style={{fontWeight: 'bold'}}>
                      {name}
                    </Row>
                    <Row style={{marginTop: 4, fontWeight: 'bold', color: '#008000'}}>
                      Valid
                    </Row>
                  </Container>
                </Row>
              </Container>
            </div>
          </Row>
          <Row style={{marginTop: 40, marginLeft: 20, marginRight: 20}}>
            <Col>
              <img src={QR_Code} className='QR_Code' alt='QR_Code' />
            </Col>
            <Col style={{textAlign: 'left', fontWeight: 'bold'}}>
              <Clock format={'DD MMM YYYY, h:mm:ss a'} ticking={true} timezone={'Asia/Singapore'} />
            </Col>
          </Row>
          <Row className='Note' style={{marginLeft: 20, marginTop: 30, marginRight: 20, fontSize: 14, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.5)'}}>
            NOTE: Please present this screen with your NRIC/Workpass to cashier before payment.
          </Row>
          <Row style={{justifyContent: 'center', marginTop: 0}}>
            <div className='FormButton' onClick={handleClick}>
              View Benefits
            </div>
          </Row>
        </Container>
      </MobileView>
    </div>
  )
}

export default Card
